<template>
  <v-autocomplete
    v-bind="$attrs"
    :ref="props.getRef"
    v-model:search-input="searchQuery"
    :items="localItems"
    :model-value="selectedItem"
    :loading="searching || props.loading"
    :multiple="props.multiple"
    :placeholder="props.placeholderKey ? t(props.placeholderKey) : undefined"
    append-inner-icon="i-mdi:magnify"
    menu-icon=""
    :item-title="props.titleKey"
    item-value="id"
    validate-on="lazy blur"
    no-filter
    :auto-select-first="props.autoSelectFirst"
    @update:model-value="emitSelection"
    @update:search="emitSearch"
  >
    <template v-if="!props.loading && totalItems === 0" #no-data>
      <div class="pa-2">
        {{ t('common.noData') }}
      </div>
    </template>
    <template v-if="props.chip" #selection="{ item, index }">
      <Chip :key="index">
        {{ item.title }}
      </Chip>
    </template>
    <template #item="{ props: itemProps, item }">
      <v-list-item
        v-bind="itemProps"
        :title="item.raw[titleKey]?.toString()"
        :subtitle="getSubtitle(item)"
      />
    </template>
    <template v-if="showLoadMore && !props.loading" #append-item>
      <div v-intersect="handleIntersection" class="pa-4 teal--text">
        {{ t('common.loadingMoreItems') }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import type { VAutocomplete } from 'vuetify/components';
import type { VNodeRef } from 'vue';

import { formatTimeSpan } from '@/utils/time';
import {
  AutocompleteSubtitleType,
  DEFAULT_ITEMS_PER_PAGE,
} from '@/constants/autocomplete';
import { uniqBy } from 'lodash';

type Subtitle = {
  key: string;
  labelKey: string;
  type?: string;
};

export type AutocompleteItem = Record<string, string | number | boolean | null>;

// sfc-compiler has problem with inheriting complex types
// from libraries, therefor we need to use following ignore statement
type AutocompleteProps = InstanceType<typeof VAutocomplete>['$props'];
interface Props extends /* @vue-ignore */ AutocompleteProps {
  modelValue: object | string | string[] | null | undefined;
  placeholderKey?: string;
  multiple?: boolean;
  chip?: boolean;
  getData?: (
    query: string,
    ids?: string[],
    offset?: number,
    limit?: number,
  ) => Promise<{ items?: AutocompleteItem[]; totalItems?: number }>;
  getRef?: VNodeRef;
  autoSelectFirst?: boolean;
  titleKey?: string;
  subtitles?: Subtitle[];
  clearAfterSelect?: boolean;
}

type Emits = {
  (e: 'update:modelValue', value: AutocompleteProps['modelValue']): void;
  (e: 'update:search', value: AutocompleteProps['search']): void;
};

const props = withDefaults(defineProps<Props>(), {
  placeholderKey: undefined,
  getData: undefined,
  getRef: undefined,
  autoSelectFirst: true,
  titleKey: 'value',
  subtitles: undefined,
  clearAfterSelect: false,
});
const emit = defineEmits<Emits>();

const { t } = useI18n();

const currentPage = ref(0);
const totalItems = ref(0);
const localItems = ref([] as AutocompleteItem[]);
const searchQuery = ref('');
const searching = ref(false);
const showLoadMore = computed(() => localItems.value.length < totalItems.value);

const debouncedGetData = props.getData
  ? useDebounceFn(async () => {
      searching.value = true;

      const selectedIds = props.multiple
        ? (props.modelValue as string[])
        : undefined;
      const response = await props.getData!(
        searchQuery.value,
        selectedIds,
        currentPage.value * DEFAULT_ITEMS_PER_PAGE,
        DEFAULT_ITEMS_PER_PAGE,
      );

      if (currentPage.value === 0) {
        localItems.value = response.items as AutocompleteItem[];
      } else {
        localItems.value = uniqBy(
          [...localItems.value, ...(response.items as AutocompleteItem[])],
          'id',
        );
      }

      totalItems.value = response.totalItems || 0;
      searching.value = false;
      return response;
    }, 300)
  : null;

onMounted(async () => {
  selectedItem.value = props.modelValue as AutocompleteItem;

  if (debouncedGetData) {
    await debouncedGetData();
  }
});

const handleIntersection = async (isIntersecting: boolean) => {
  if (!isIntersecting) {
    return;
  }

  if (!debouncedGetData) {
    return;
  }

  currentPage.value += 1;

  await debouncedGetData();
};

const selectedItem = ref<AutocompleteItem | null>(null);

watch(
  () => props.modelValue,
  (value) => {
    selectedItem.value = value as AutocompleteItem;
  },
);

watch(selectedItem, (value) => {
  if (value && props.clearAfterSelect) {
    selectedItem.value = null;
  }
});

const emitSelection = async (value: AutocompleteItem) => {
  selectedItem.value = value;
  searchQuery.value = '';
  currentPage.value = 0;

  emit('update:modelValue', value);

  if (!debouncedGetData) {
    return;
  }

  await debouncedGetData();
};

const debouncedSearch = useDebounceFn(async (value: string) => {
  if (searching.value) {
    return;
  }

  if (selectedItem.value && selectedItem.value[props.titleKey] === value) {
    return;
  }

  searchQuery.value = value;
  currentPage.value = 0;

  emit('update:search', value);

  if (!debouncedGetData) {
    return;
  }

  await debouncedGetData();
}, 300);

const emitSearch = (value: string) => {
  debouncedSearch(value);
};

const getSubtitle = (item: { raw: AutocompleteItem }) => {
  if (props.subtitles && Array.isArray(props.subtitles)) {
    const subtitles = props.subtitles.map((subtitle) => {
      if (
        subtitle.type &&
        subtitle.type === AutocompleteSubtitleType.timeSpan
      ) {
        return `${t(subtitle.labelKey)}: ${formatTimeSpan(item.raw[subtitle.key]!.toString())}`;
      }

      return `${t(subtitle.labelKey)}: ${item.raw[subtitle.key]}`;
    });

    return subtitles.join(', ');
  }

  return undefined;
};

defineExpose({
  emitSearch,
});
</script>
